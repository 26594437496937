<template>
  <div class="device">
    <div class="el-alert el-alert--info is-light device-tip" style="display: block">
      <div>
        <span class="device-tip-title">相关说明</span>
        <el-button type="text">所有设备</el-button>
      </div>
      <p>直接支付模式说明：设备开启直接支付模式；
        客户手机扫码设备进入小程序过程中，小程序将自动发起饮品杯量选取引导支付；支付成功后设备自动开始制作。
        点击【下方设备直接支付模式开关】，即可完成切换。
      </p>
      <p>绑定设备被解绑后才能被其他门店绑定；可点击上方【所有设备】查看设备绑定门店信息</p>
    </div>
    <el-row>
      <el-col :span="6" class="device-item">
        <div class="device-item-content add" @click="showDevice=true">
          <span><i class="el-icon-plus"></i> 绑定设备</span>
        </div>
      </el-col>
      <el-col :span="6" v-for="(device, key) in devices" class="device-item" :key="key">
        <div class="device-item-content">
          <p class="name">
            <span class="name-title">{{device.name}}</span>
          </p>
          <p><span class="label">设备编号:</span><span>{{device.uuid}}</span></p>
          <p><span class="label">产品名称:</span> <span>{{device.productName}}</span></p>
          <p>
            <span class="label">设备状态:</span>
            <span v-if="device.connectStatus == 2">在线</span>
            <span v-else>离线</span>
          </p>
          <p class="unbind">
            <el-tooltip :content="`直接支付模式:${device.directPay === 1? '开启': '关闭'}`" placement="top">
            <span class="name-tip"><el-switch @change="handleChangeMod(device)" v-model="device.directPay" :active-value="1"
                                              :inactive-value="0"></el-switch></span>
            </el-tooltip>
            <el-button type="text" @click="handleUnBindDevice(device, key)"><i class="iconfont icon-jiebang"></i> 解绑</el-button>
          </p>
        </div>
      </el-col>
    </el-row>

    <el-drawer
      title="可绑定设备列表"
      class="bind"
      :visible.sync="showDevice">
     <device-bind  @cancel="showDevice = false"
                   @commit="reloadDevice"
                  :uuid="shop.uuid"></device-bind>
    </el-drawer>
  </div>

</template>

<script>
 import DeviceBind from './components/device-bind'
  export default {
    name: 'device',
    components: {
      DeviceBind
    },
    props: {
      shop: {
        type: Object,
        default: () => {
        }
      }
    },
    mounted: function () {
      this.loadShopDevice(this.shop.uuid || this.$route.params.uuid)
    },
    data: function () {
      return {
        showDevice: false,
        devices: [],
      }
    },
    methods: {
      loadShopDevice(uuid) {
        this.$api('mkt.shop.devices', {uuid: uuid}).then(data => {
          this.devices = data || []
        })
      },

      handleUnBindDevice(device, idx) {
        this.$confirm(`是否将设备【${device.name}】与门店解绑?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api('mkt.shop.unBindDevice',{}, {
            uuid: this.shop.uuid,
            deviceUuid: device.uuid,
          }).then(() => {
            this.$message.success('解绑成功')
            this.devices.splice(idx, 1)
          })
        })
      },

      handleChangeMod(v) {
        let sw = v.directPay ===0? 1:0
        this.$api('mkt.shop.oprDevicePayMode',{}, {
          uuid: this.shop.uuid,
          deviceUuid: v.uuid,
          directPay: v.directPay,
        }).then(() => {
          this.$message.success('更新成功')
        }).catch(() => {
          v.directPay = sw
        })
      },

      reloadDevice() {
       this.showDevice = false
        this.loadShopDevice(this.shop.uuid || this.$route.params.uuid)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .device {
    &-tip {
      font-size: 13px;
      margin-bottom: $large-space;
      color: rgba(0, 0, 0, .6);

      &-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.75);
        margin-right: 4px;
      }

      counter-reset: blogs 0;

      p {
        padding-left: 26px;
        text-indent: -20px;
        margin-bottom: 4px;
      }

      p:before {
        counter-increment: blogs 1;
        content: counter(blogs) "、";

      }

      .el-button {
        font-size: 13px;
      }
    }

    .device-item {
      padding: 0 0 $small-space $small-space;

      .name {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
      }


      .name-title {
        font-size: 16px;
        font-weight: 500;
        margin-right: $small-space;
      }

      .name-tip {
        margin-bottom: 2px;
        display: inline-block;
      }

      .add {
        cursor: pointer;
        color: $theme-color;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        text-align: center;
      }

      &-content {
        height: 112px;
        border-radius: $box-radius;
        border: 1px solid $content-border-color;
        padding: $middle-space;
        font-size: 13px;
        position: relative;
        cursor: pointer;

        .unbind {
          position: absolute;
          top: 0;
          right: $small-space;
          margin-top: $small-space;
          cursor: pointer;
          color: $theme-color;
        }

        .label {
          margin-right: 5px;
          color: $remark-text-color;
        }

        .value {
          font-weight: 500;
          display: inline-block;
          width: 120px;
        }

        .device-btn {

          display: flex;

          div {
            flex: 1;
            text-align: center;
            font-weight: 500;
            margin-top: 8px;
            border-right: 2px solid $color-base;
          }

          div:last-of-type {
            border: none;
          }

          div:hover {
            color: $theme-color;
            cursor: pointer;
            border-color: $color-base;
          }
        }
      }

      &-content:hover {
        border-color: $theme-color;
      }
    }
  }

  .bind {
    ::v-deep .el-drawer {
      min-width: 1200px;
      padding: 0 $container-padding;
      padding-bottom: $middle-space;

      .el-drawer__header {
        padding-left: 0;
        margin-bottom: $middle-space;
        font-size: 18px;
        color: $color-black;
      }

      .el-drawer__body {
        height: 100%;
        overflow-y: scroll;
      }
    }

    .label {
      @include font-medium-s();
      font-weight: 500;
    }
  }
</style>
