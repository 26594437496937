<template>
  <el-form label-width="100px" size="small">
    <div class="section" v-if="showDelete && $btnCheck('mkt_shop_delete')">
      <p class="section-title">删除门店</p>
      <el-button type="danger" size="mini" @click="handleDelete">删除门店</el-button>
      <p class="yk-tip" style="margin-top: 4px">删除门店信息会导致相关信息被清空，请谨慎操作</p>
    </div>
    <div class="section">
      <p class="section-title">基础信息</p>
      <el-form-item>
        <span class="yk-label__require" slot="label">门店名称</span>
        <el-input v-model="info.name" rows="3" maxlength="12" placeholder="请输入门店名称" show-word-limit></el-input>
      </el-form-item>

      <el-form-item>
        <span class="yk-label__require" slot="label">门店介绍</span>
        <el-input type="textarea" v-model="info.description"
                  rows="5" maxlength="255" placeholder="请输入店铺描述" show-word-limit>
        </el-input>
      </el-form-item>
      <el-row>
        <el-col :span="10">
          <el-form-item label="联系人">
            <span class="yk-label__require" slot="label">联系人</span>
            <el-input v-model="info.contactName" placeholder="请输入店家联系人"></el-input>
          </el-form-item>

        </el-col>
        <el-col :span="14">
          <el-form-item label="联系电话">
            <span class="yk-label__require" slot="label">联系电话</span>
            <el-input v-model="info.contactPhone" placeholder="请输入店家联系电话"></el-input>
          </el-form-item>

        </el-col>

        <el-col :span="10">
          <el-form-item label="责任人">
            <el-input v-model="info.dutyName" placeholder="请输入店家责任人"></el-input>
          </el-form-item>

        </el-col>
        <el-col :span="14">
          <el-form-item label="责任人电话">
            <el-input v-model="info.dutyPhone" placeholder="请输入店家责任人电话"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="营业时间">
        <span class="yk-label__require" slot="label">营业时间</span>
        <div>
          <el-checkbox-group v-model="workDay">
            <el-checkbox :label="weekInfo.value"
                         :key="weekInfo.value"
                         :value="weekInfo.value"
                         v-for="weekInfo in weekInfos">{{weekInfo.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-time-select
          class="margin-8"
          v-model="startAtTxt"
          value-format="timestamp"
          :picker-options="pickerOpt"
          placeholder="营业开始时间">
        </el-time-select>
        <el-time-select
          v-model="endAtTxt"
          :picker-options="pickerOpt"
          value-format="timestamp"
          placeholder="营业结束时间">
        </el-time-select>
        <p class="yk-tip">时间段之外,门店停止营业</p>
      </el-form-item>
    </div>

    <div class="section">
      <p class="section-title">地理位置</p>
      <el-form-item label="门店定位">
        <span class="yk-label__require" slot="label">门店定位</span>
        <map-container ref="map"></map-container>
      </el-form-item>
      <el-form-item label="详细地址">
        <span class="yk-label__require" slot="label">详细地址</span>
        <el-input type="textarea" v-model="info.address"
                  rows="3" maxlength="128" placeholder="填写门牌号信息" show-word-limit></el-input>
      </el-form-item>
    </div>

    <div class="section">
      <p class="section-title">媒体信息</p>
      <el-form-item label="轮播图">
        <material-manager v-model="info.slides" :limit="5" @change="(url) => info.slides = url"></material-manager>
        <p class="yk-tip">尺寸：750*300像素，小于1M，支持jpg、png、jpeg格式</p>
      </el-form-item>
    </div>


    <el-form-item align="center">
      <el-button type="primary" @click="handleCommit">
        <slot>立即创建</slot>
      </el-button>
      <el-button @click="$emit('cancel')">取消</el-button>
    </el-form-item>
  </el-form>

</template>

<script>
  import MapContainer from '@/components/Map'

  import {checkObj, fillObj} from '@/utils/check'

  export default {
    name: '',
    components: {
      MapContainer
    },
    props: {
      shop: Object,
      showDelete: {
        type: Boolean,
        default: false,
      }
    },

    mounted: function () {
      fillObj(this.info, this.shop)
      this.info.slides = this.shop.slides || []
      this.workDay = []
      for (let i = 0; i < 7; i++) {
        let v = (1 << i)
        if (this.info.week & v !== 0) {
          this.workDay.push(v)
        }
      }
      this.startAtTxt = this.toTxt(this.shop.startAt)
      this.endAtTxt = this.toTxt(this.shop.endAt)
      this.$nextTick(() => {
        this.$refs.map.initMap(this.shop)
      })
    },
    data: function () {
      return {
        ts: [],
        info: {
          name: '',
          description: '',
          contactName: '',
          contactPhone: '',
          dutyName: '',
          dutyPhone: '',
          week: 127,
          startAt: 1800,
          endAt: 52200,
          province: '',
          city: '',
          cityCode: '',
          address: '',
          lat: '',
          lng: '',
          sendType: 1,
          slides: []
        },
        workDay: [],
        startAtTxt: '',
        endAtTxt: '',
        pickerOpt: {
          start: '00:00',
          step: '00:15',
          end: '23:59'
        },
        weekInfos: [
          {name: '周一', value: 1},
          {name: '周二', value: 2},
          {name: '周三', value: 4},
          {name: '周四', value: 8},
          {name: '周五', value: 16},
          {name: '周六', value: 32},
          {name: '周日', value: 64},
        ],
      }
    },
    methods: {
      toTs(txt) {
        return new Date("1970-01-01 " + txt + ":00").getTime() / 1000;
      },
      toTxt(ts) {
        if (ts === 0) {
          return ''
        }
        return new Date(ts * 1000).Format('hh:mm');
      },
      handleCommit() {
        let weeks = 0
        this.workDay = this.workDay || []
        this.workDay.forEach((w) => {
          weeks += w
        })
        this.info.week = weeks
        if (weeks === 0) {
          this.$message.warning('请选择营业周期')
          return
        }
        if (!this.startAtTxt || !this.endAtTxt) {
          this.$message.warning('请选择营业时间')
          return
        }

        this.info.startAt = this.toTs(this.startAtTxt)
        this.info.endAt = this.toTs(this.endAtTxt)

        let pos = this.$refs.map.getPos()
        let address = this.info.address
        this.info = {...this.info, ...pos}
        this.info.address = address || this.info.address
        this.info.lat += ''
        this.info.lng += ''

        let check = checkObj(this.info, [
          {value: "name", message: "请填写店铺名称"},
          {value: "description", message: "请填写店铺介绍"},
          {value: "contactName", message: "请填写联系人"},
          {value: "contactPhone", message: "请填写联系方式"},
          {value: "province", message: "请填写店铺地址"},
          {value: "lat", message: "定位信息获取失败"},
          {value: "address", message: "请填写门店详细地址"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        this.$emit('commit', this.info)
      },
      handleDelete() {
        this.$confirm('是否将门店从企业中移除', '删除提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('delete')
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .section {
    margin-bottom: $middle-space;

    &-title {
      @include font-medium();
      margin-bottom: 32px;
    }

    &-title:after {
      content: " ";
      display: block;
      border-bottom: 1px solid $content-border-color;
      margin-bottom: $middle-space;
      margin-top: 4px;
    }

    .margin-8 {
      margin-right: $middle-space;
    }
  }

</style>
