import request from '@/utils/request';
/**
 * 获取配送设置
 */
export function GetDeliverySetting () {
  return request({
    url: '/api/v1/shop/manage/delivery/setting',
    method: 'GET'
  })
}

/**
 * 更新更新配送设置
 * @param key
 * @param value
 */
export function UpdateDeliverySetting ({ key, value }) {
  return request({
    url: '/api/v1/shop/manage/delivery/setting/update',
    method: 'POST',
    data: { key: key, value: value }
  })
}

/**
 * 获取自提点列表
 */
export function DeliverySelfPickPointsList (obj) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoints',
    method: 'GET',
    params: obj
  })
}

/**
 * 获取自提点总数
 */
export function DeliverySelfPickPointCount (keyword) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoint/count',
    method: 'GET',
    params: keyword
  })
}

/**
 * 获取自提点详情
 */
export function DeliverySelfPickPoint (uuid) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoint',
    method: 'GET',
    params: { uuid }
  })
}
/**
 * 新增自提点
 */
export function AddDeliverySelfPickPoint (obj) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoint/add',
    method: 'POST',
    data: obj
  })
}
/**
 * 删除自提点
 */
export function DeleteDeliverySelfPickPoint (uuid) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoint/delete',
    method: 'GET',
    params: { uuid }
  })
}

/**
 * 更新自提点
 */
export function UpdateDeliverySelfPickPoint (uuid, obj) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoint/update',
    method: 'POST',
    params: { uuid },
    data: obj
  })
}

/**
 * 复制自提点
 */
export function CopyDeliverySelfPickPoint (uuid) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoint/copy',
    method: 'GET',
    params: { uuid }
  })
}

/**
 * 切换自提点状态
 */
export function SwtichDeliverySelfPickPoint (uuid, status) {
  return request({
    url: '/api/v1/shop/manage/delivery/selfPickPoint/switch',
    method: 'POST',
    params: { uuid },
    data: { status }
  })
}

/**
 * 获取同城配送设置
 */
export function DeliveryCity () {
  return request({
    url: '/api/v1/shop/manage/delivery/city',
    method: 'GET'
  })
}
/**
 * 更新同城配送设置
 */
export function UpdateDeliveryCity (obj) {
  return request({
    url: '/api/v1/shop/manage/delivery/city/update',
    method: 'POST',
    data: obj
  })
}

/**
 * 更新业务类型
 */
export function UpdateCityBizType (bizType) {
  return request({
    url: '/api/v1/shop/manage/delivery/city/bizType',
    method: 'POST',
    data: { bizType }
  })
}
/**
 * 更新第三方配送状态
 */
export function UpdateCityThirdParty (serviceProvider, status) {
  return request({
    url: '/api/v1/shop/manage/delivery/city/thirdParty',
    method: 'POST',
    data: { serviceProvider, status }
  })
}

/**
 * 获取运费模板列表
 */
export function DeliveryExpresses (obj) {
  return request({
    url: '/api/v1/shop/manage/delivery/expresses',
    method: 'GET',
    params: obj
  })
}

/**
 * 获取运费模板总数
 */
export function DeliveryExpressesCount (keyword) {
  return request({
    url: '/api/v1/shop/manage/delivery/express/count',
    method: 'GET',
    params: keyword
  })
}

/**
 * 获取运费模板详情
 */
export function DeliveryExpress (uuid) {
  return request({
    url: '/api/v1/shop/manage/delivery/express',
    method: 'GET',
    params: { uuid }
  })
}
/**
 * 新增运费模板
 */
export function AddDeliveryExpress (obj) {
  return request({
    url: '/api/v1/shop/manage/delivery/express/add',
    method: 'POST',
    data: obj
  })
}
/**
 * 删除运费模板
 */
export function DeleteDeliveryExpress (uuid) {
  return request({
    url: '/api/v1/shop/manage/delivery/express/delete',
    method: 'GET',
    params: { uuid }
  })
}

/**
 * 更新运费模板
 */
export function UpdateDeliveryExpress (uuid, obj) {
  return request({
    url: '/api/v1/shop/manage/delivery/express/update',
    method: 'POST',
    params: { uuid },
    data: obj
  })
}

/**
 * 复制运费模板
 */
export function CopyDeliveryExpress (uuid) {
  return request({
    url: '/api/v1/shop/manage/delivery/express/copy',
    method: 'GET',
    params: { uuid }
  })
}

/**
 * 获取通用设置
 */
export function settingCommon () {
  return request({
    url: '/api/v1/shop/manage/setting/common',
    method: 'GET'
  })
}

/**
 * 更新通用设置
 */
export function settingCommonUpdate (obj) {
  return request({
    url: '/api/v1/shop/manage/setting/common/update',
    method: 'POST',
    data: obj
  })
}

/**
 * 获取支付设置
 */
export function settingPayment () {
  return request({
    url: '/api/v1/shop/manage/setting/payment',
    method: 'GET'
  })
}

/**
 * 更新支付设置
 */
export function settingPaymentUpdate (data) {
  return request({
    url: '/api/v1/shop/manage/setting/payment/update',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

/**
 * 获取基础设置
 */
export function settingBasic () {
  return request({
    url: '/api/v1/shop/manage/setting/basic',
    method: 'GET'
  })
}
/**
 * 更新基础设置
 */
export function settingBasicUpdate (data) {
  return request({
    url: '/api/v1/shop/manage/setting/basic/update',
    method: 'POST',
    data
  })
}
