<template>
  <div class="shop-create">
   <div class="info">
     <info :shop="shop"
           :show-delete="true"
           @delete="$emit('delete')"
           @commit="(info) => {$emit('commit', info)}">
       <slot>更新信息</slot>
     </info>
   </div>
  </div>

</template>

<script>
  import Info from './components/info'
  export default {
    name: '',
    components: {
      Info
    },
    props: {
      shop: {
        type: Object,
        default : () =>{}
      }
    },
    mounted: function () {
    },
    data: function () {
      return {
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .shop-create{
    background-color: white;
    display: block;
    @include container();
    flex: 0;
    max-height: 100%;
   .info {
     max-width: 768px;
     margin: auto;
   }
  }
</style>
