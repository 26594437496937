<template>
  <div class="shop color-bg" :class="option.clazz">
    <step-container :name="shop.name">
      <template slot="step">
        <el-tabs v-model="step" class="shop-nav" @tab-click="handleTab">
          <el-tab-pane :name="opt.path" :label="opt.name" v-for="opt in options" :key="opt.path"></el-tab-pane>
        </el-tabs>
      </template>
      <template slot="back">
      </template>
    </step-container>
    <keep-alive>
      <component class="shop-container" v-bind:is="option.comp" :shop="shop"
                 @delete="handleDelete"
                 @commit="handleUpdate"></component>
    </keep-alive>
  </div>

</template>

<script>
  import overview from './overview'
  import goods from './goods'
  import orders from './orders'
  import devices from './devices'
  import info from './info'
  import city from './city'

  import {fillObj} from "@/utils/check";

  export default {
    name: '',
    components: {
      overview,
      goods,
      orders,
      devices,
      info,
      city,
    },
    mounted: function () {
      this.getDetail()
      this.option = this.options[0]
      if (this.$btnCheck('mkt_shop_update')) {
        let idx = this.options.findIndex((item) => {
          return item.path === 'shop.info'
        })
        if (idx < 0) {
          this.options.push({
            name: '基础信息',
            path: 'shop.info',
            comp: 'info',
            clazz: ''
          },)
        }
      }
    },
    data: function () {
      return {
        shop: {
          name: '',
        },
        shopUseOrg: 1,
        step: 'shop.goods',
        option: {
          comp: '',
          path: '',
        },
        options: [
          // {
          //   name: '概览',
          //   path: 'shop.overview',
          //   comp: 'overview',
          // },
          {
            name: '商品',
            path: 'shop.goods',
            comp: 'goods',
            clazz: 'height'

          },
          {
            name: '订单',
            path: 'shop.orders',
            comp: 'orders',
            clazz: ''
          },
          // {
          //   name: '分润管理',
          //   path: 'shop.fee',
          //   comp: 'fee',
          // },
          {
            name: '设备',
            path: 'shop.device',
            comp: 'devices',
            clazz: ''
          },
          // {
          //   name: '配送',
          //   path: 'shop.city',
          //   comp: 'city',
          // },
        ],
      }
    },
    methods: {
      handleTab(tab) {
        this.option = this.options[parseInt(tab.index)]
      },
      handleDelete() {
        this.$api('mkt.shop.delete', {uuid: this.shop.uuid}).then(() => {
          this.$message.success('门店已被删除')
          this.$router.back()
        })
      },
      async getDetail() {
        this.$api('mkt.shop.detail', {uuid: this.$route.params.uuid}).then(res => {
          this.shop = res
          this.shopUseOrg = this.shop.useOrg
          this.shop.slides = this.shop.slides || []
        })
      },

      handleUpdate(info) {
        info.useOrg = info.useOrg || this.shop.useOrg
        this.$api('mkt.shop.update', {}, {...info, uuid: this.$route.params.uuid}).then(() => {
          fillObj(this.shop, info)
          this.shopUseOrg = this.shop.useOrg
          this.$message.success('更新成功')
        }).catch(() => {
          this.$message.error('系统异常更新失败')
          this.shop.useOrg = this.shopUseOrg
        })
      }
    },
  }
</script>

<style lang="scss">
  .shop {
    .el-tabs {
      margin-top: 4px;
    }

    .el-tabs__header {
      margin: 0;
    }

    &-container {
      flex: 1;
      max-height: calc(100% - 120px);
      min-height: 532px;
      background: white;
      margin: $middle-space;
      padding: $container-padding;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }
  }
  .height {
    display: flex;
    flex-direction: column;
    height: calc(100% - 52px);
  }
</style>
