<template>
  <order :pagination="pagination" ref="order"></order>

</template>

<script>

  import Order from '@/components/Order/shop'

  export default {
    name: '',
    components: {
      Order
    },
    mounted () {
      this.pagination.shopUuid = this.$route.params.uuid
      this.$refs.order.loadOrder()
    },
    data: function () {
      return {
        pagination: {
          shopUuid: '',
        },
      }
    },

    methods: {
    },
  }
</script>
