<template>
  <div class="device-list">
    <div class="device-list-title">
      <p class="name">{{title}}</p>
      <el-input class="cond" v-model="pagination.name"
                clearable
                placeholder="请输入设备编号/设备名称" size="small"></el-input>
      <el-button size="mini" type="primary" @click="loadDevice" style="margin-left: 8px">筛选</el-button>
    </div>

    <el-table
      class="device-list-table"
      :row-key="record => record.uuid"
      :data="devices"
      :loading="loading"
      ref="multipleTable"
      :reserve-selection="true"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <empty slot="empty"></empty>
      <el-table-column
        v-if="select"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="设备信息" width="160px">
        <template slot-scope="scope">
          <p> {{ scope.row.uuid }}</p>
          <p> {{ scope.row.name }}</p>
        </template>
      </el-table-column>
      <el-table-column label="地址信息" width="260px">
        <span slot-scope="scope">{{scope.row.province}}{{scope.row.city}}{{scope.row.address}}</span>
      </el-table-column>
      <el-table-column label="状态" width="130px">
        <template slot-scope="scope">
          <p>运行状态：{{scope.row.runStatus | filterRunStatus}}</p>
          <p>连接状态：{{scope.row.connectStatus | filterStatus}}</p>
        </template>
      </el-table-column>
      <el-table-column label="锁定状态" width="80px" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.isLock === 1">已锁定</template>
          <template v-else>未锁定</template>
        </template>

      </el-table-column>

      <el-table-column label="认证类型" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.authType === 1" style="color: #1678fe">所有</template>
          <template v-else style="color: #f0ad4e">授权</template>
        </template>
      </el-table-column>
      <el-table-column label="最近登录" width="120px">
        <template slot-scope="scope">
          {{new Date(scope.row.lastLoginAt * 1000).Format('yyyy-MM-dd')}}
        </template>
      </el-table-column>

      <el-table-column label="品牌名称" prop="brandName" width="120px"></el-table-column>
      <el-table-column label="产品名称" prop="productName" width="120px"></el-table-column>
      <el-table-column label="注册时间" width="120px">
        <template slot-scope="scope">
          {{new Date(scope.row.registerAt * 1000).Format('yyyy-MM-dd')}}
        </template>

      </el-table-column>
      <slot></slot>
    </el-table>

    <el-pagination
      @current-change="handleDeviceList"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
  </div>

</template>

<script>
  export default {
    name: '',
    components: {},
    mounted() {
      this.loadDevice()
    },
    filters: {
      filterRunStatus(runStatus) {
        switch (runStatus) {
          case 0:
            return '未知'
          case 1:
            return '正常运行'
          case 2:
            return '维修中'
        }
      },

      filterStatus(status) {
        switch (status) {
          case 0:
            return '初始化'
          case 1:
            return '离线'
          case 2:
            return '在线'
        }
      },

    },
    props: {
      select: Boolean,
      title: {
        type: String,
        default: '设备列表'
      },
      deviceList: {
        type: Function,
        default: () => {
          return function () {
          }
        }
      },
      deviceUnion: {
        type: Function,
        default: () => {
          return function () {
          }
        }
      }
    },
    data: function () {
      return {
        devices: [],
        loading: false,
        pagination: {
          name: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
        selects: []
      }
    },
    methods: {
      handleDeviceList(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        this.loading = true
        let vue = this
        vue.deviceList(this.pagination, data => {
          vue.devices = data || []
          let selects = vue.selects.slice(0, vue.selects.length)
          this.$nextTick(() => {
            selects.forEach(item => {
              for (let i = 0; i < this.devices.length; i++) {
                if (vue.devices[i].uuid === item.uuid) {
                  vue.$refs.multipleTable.toggleRowSelection(item, true);
                  break
                }
              }
            })
          })
          this.loading = false
        })
      },
      loadDevice() {
        this.loading = true
        this.deviceUnion(this.pagination, data => {
          this.pagination.total = parseInt(data.total)
          this.devices = data.list || []
          this.loading = false
        })
      },
      clearAll() {
        this.$refs.multipleTable.clearSelection()
        this.selects = []
        this.$emit('select-change', [])
      },
      clearSingle(data) {
        let idx = this.selects.findIndex(item => {return item.uuid === data.uuid})
        if (idx>=0){
          this.selects.splice(idx, 1)
          this.$emit('select-change', this.selects)
          this.$refs.multipleTable.toggleRowSelection(this.selects[idx], false);
        }
      },
      handleSelectionChange(val) {
        val = val || []
        // 过滤出当前页未选的元素
        this.selects = this.selects.filter(item => {
          let idx = -1
          for (let i = 0; i < this.devices.length; i++) {
              if (this.devices[i].uuid === item.uuid) {
                idx = i
                break
              }
          }
          return idx === -1
        })

        val.forEach(res => {
          this.selects.push(res)
        })
        this.$emit('select-change', this.selects)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .device {
    &-list {
      &-title {
        margin-top: $middle-space;
        display: flex;
        margin-bottom: $middle-space;

        .name {
          @include font-medium();
          font-weight: 500;
          flex: 1;
        }

        .cond {
          width: 240px;
        }
      }

      .department-name {
        display: block;
        min-width: 108px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
</style>
