<template>
  <div class="goods">
    <p style="font-size: 16px; margin-bottom: 8px">基本设定</p>
    <el-form size="small" label-width="80px">
      <el-form-item>
        <span slot="label" style="font-size: 13px">商品库存： </span>
        <div class="input-flex">
          <el-input v-model.number="bind.stocks" placeholder="请输入商品库存"></el-input>
          <div class="yk-tip"><i class="el-icon-question"></i> 统一设定商品库存，库存为0 商品自动隐藏</div>
        </div>
      </el-form-item>

      <el-form-item>
        <span slot="label" style="font-size: 13px">虚拟销售： </span>
        <div class="input-flex">
          <el-input v-model.number="bind.virtualSales" placeholder="请输入虚拟销售量"></el-input>
          <div class="yk-tip"><i class="el-icon-question"></i> 统一设定商品虚拟销售量</div>
        </div>
      </el-form-item>
    </el-form>
    <el-row>

      <el-col :span="5">
        <p style="font-size: 16px; margin-top: 6px;">绑定商品</p>
      </el-col>
      <el-col :span="19" style="text-align: right">
        <el-form :inline="true" size="small">
          <el-form-item label="商品名称">
            <el-input v-model="pagination.name" clearable placeholder="请输入商品名称"
                      prefix-icon="el-icon-search"></el-input>
          </el-form-item>
          <el-form-item label="商品分类">
            <el-select v-model="pagination.sortUuid" placeholder="商品分类信息">
              <el-option :label="sort.name" :value="sort.uuid" v-for="sort in sorts" :key="sort.uuid"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loadGoods" plain>查询商品</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
      class="container"
      ref="singleTable"
      @selection-change="handleSelectionChange"
      :data="goods">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="商品信息" width="320">
        <el-row class="shop-item" slot-scope="scope">
          <el-col :span="8">
            <img :src="scope.row.thumb"/>
          </el-col>

          <el-col :span="16">
            <p class="yk-tip">商品编号：{{scope.row.uuid}}</p>
            <p>商品名称: {{scope.row.name}}</p>
          </el-col>

        </el-row>
      </el-table-column>
      <el-table-column label="商品分类" prop="sortName"></el-table-column>
      <el-table-column label="销量" prop="sales"></el-table-column>
      <el-table-column label="基础价格">
        <span slot-scope="scope"> {{(scope.row.linePrice / 100) | F1000}}</span>
      </el-table-column>
      <el-table-column label="排序编号" prop="sort"></el-table-column>

    </el-table>

    <div class="footer">
      <el-button type="primary" size="small" @click="handleCommit">提交</el-button>
      <el-button size="small" @click="$emit('cancel')">取消</el-button>
      <span style="margin-left: 16px; font-size: 12px">
          <span style="display: inline-block;margin-right: 8px;">已选商品数量:{{bind.goods.length}}</span>
          <el-popover trigger="hover" placement="right" width="800">
            <el-table :data="bind.goods">
              <el-table-column label="商品信息" width="320">
                <el-row class="shop-item" slot-scope="scope">
                  <el-col :span="8">
                    <img :src="scope.row.thumb"/>
                  </el-col>

                  <el-col :span="16">
                    <p class="yk-tip">商品编号：{{scope.row.uuid}}</p>
                    <p>商品名称: {{scope.row.name}}</p>
                  </el-col>

                </el-row>
              </el-table-column>
              <el-table-column label="商品分类" prop="sortName"></el-table-column>
              <el-table-column label="销量" prop="sales"></el-table-column>
              <el-table-column label="基础价格">
                 <span slot-scope="scope"> {{(scope.row.linePrice / 100) | F1000}}</span>
              </el-table-column>
              <el-table-column label="排序编号" prop="sort"></el-table-column>
              <el-table-column label="操作">
                <el-button size="small" slot-scope="scope" @click="handleRemove(scope.row)"> 移除</el-button>
              </el-table-column>
            </el-table>
            <el-button slot="reference" type="text" size="mini"><i class="el-icon-view"></i> 查看</el-button>
          </el-popover>
          <el-button type="text" size="mini" @click="$refs.singleTable.clearSelection()"><i class="el-icon-delete"></i> 清空</el-button>
        </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    props: {
      shopUuid: String
    },
    mounted: function () {
      this.loadSorts()
      if (this.shopUuid) {
        this.loadGoods()
      }
    },
    data: function () {
      return {
        goods: [],
        sorts: [],
        pagination: {
          name: '',
          sortUuid: ''
        },
        bind: {
          stocks: 0,
          virtualSales: 0,
          goods: []
        },
      }
    },
    methods: {
      loadSorts() {
        this.$api('mkt.goods.sorts', {
          shopUse: 1,
        }).then((data) => {
          this.sorts = data || []
        })
      },
      loadGoods() {
        this.$api('mkt.shop.goodsUnbindQuery', {
          uuid: this.shopUuid,
          name: this.pagination.name,
          sortUuid: this.pagination.sortUuid
        }).then((data) => {
          this.goods = data
        })
      },
      handleCommit() {
        if (this.bind.goods.length === 0) {
          this.$message.warning('请选取商品')
          return
        }

        this.$emit('commit', this.bind)
      },

      handleClear() {
        this.$refs.singleTable.clearSelection()
      },
      handleSelectionChange(val) {
        this.bind.goods = []
        val.forEach(res => {
          this.bind.goods.push(res)
        })
      },
      handleRemove(row) {
        let refsElTable = this.$refs.singleTable; // 获取表格对象
        refsElTable.toggleRowSelection(row, false);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .goods {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .container{
    flex: 1;
    overflow-y: scroll;

  }
  .container::before {
    border: none;
    height: 0;
  }

  .footer{
    margin: $middle-space 0;
  }
  .input-flex {
    display: flex;
    align-items: center;

    .el-input {
      width: 160px;
    }

    .yk-tip {
      flex: 1;
      margin-left: $small-space;
    }
  }

  .shop-item {
    img {
      width: 80px;
    }
  }
</style>
