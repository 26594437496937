<template>
  <div class="shop-detail">
    <div class="shop-account">
      <p class="shop-filter">
        <span class="active">当天</span>
        <span>本周</span>
        <span>当月</span>
      </p>
      <ul class="clearfix">
        <li>
          <p class="item-name">成交量</p>
          <p class="item-value">{{}}</p>
          <p class="item-more">查看数据详情></p>
        </li>
        <li>
          <p class="item-name">成交金额</p>
          <p class="item-value">1835.00</p>
          <p class="item-more">查看数据详情></p>
        </li>
        <li>
          <p class="item-name">制作杯量</p>
          <p class="item-value">60</p>
          <p class="item-more">查看数据详情></p>
        </li>
        <li>
          <p class="item-name">退款金额</p>
          <p class="item-value">0</p>
          <p class="item-more">查看数据详情></p>
        </li>
      </ul>
    </div>


    <div class="shop-chart clearfix">
      <div class="chart">
        <p>商品销售排行 <span>(只显示排行前十)</span></p>
<!--        <v-chart :options="faultOpt"/>-->
      </div>
      <div class="chart">
        <p>设备销售排行<span>(只显示排行前十)</span></p>
<!--        <v-chart :options="polar"/>-->
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'shop-overview',
    computed: {
    },
    data () {
      return {
        clickIndex: 0,
        faultOpt: {
          grid: {
            top: '20'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          dataset: {
            source: [
              ['amount', 'product'],
              [58212, '卡布奇诺'],
              [78254, '拿铁'],
              [41032, '热巧克力'],
              [12755, '美式浓缩'],
              [20145, '意式浓缩'],
              [79146, '双份意式浓缩'],
              [91852, '双份美式浓缩'],
              [101852, '焦糖咖啡'],
              [20112, '大杯拿铁']
            ]
          },
          color: ['#5793f3', '#d14a61'],
          xAxis: {
            name: '金额',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#5793f3'
              }
            },
          },
          yAxis: {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: '#5793f3'
              }
            },
            axisTick: {
              show: false
            },
          },

          series: [
            {
              type: 'bar',
              encode: {
                // Map the "amount" column to X axis.
                x: 'amount',
                // Map the "product" column to Y axis
                y: 'product'
              }
            }
          ]
        },
        polar: {
          color: ['#5793f3', '#d14a61'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
            left: '15%',
            top: '6%',
            feature: {
              magicType: {
                show: true,
                type: ['line', 'bar'],
                emphasis: {
                  iconStyle: {
                    color: '#5793f3'
                  }
                }
              },
            }
          },
          legend: {
            data: ['蒸发量', '降水量']
          },
          xAxis: {
            type: 'category',
            data: ['中央广场店', '咖啡吧', '科技园1号店', '科技园2号店', '科技园3号店', '科技园5号店', '科技园5号店', '风扇八号', '风扇九号', '咖啡吧', '风扇十一号', '风扇十二号'],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#5793f3'
              }
            },
          },
          yAxis: {
            type: 'value',
            name: '销量',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#5793f3'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
          ,
          series: [
            {
              name: '杯量',
              type: 'bar',
              data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
            },

          ]
        },
      }
    },
    props: {
      shop: {
        type: Object,
        default : () =>{}
      }
    },
    mounted: function () {
    },
    methods: {
    },
    components: {}
  }
</script>

<style lang="scss">

  $shop-title-height: 58px;
  .shop-detail {
    height: 100%;
    .shop-title {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, .1);

      .shop-title-item {
        text-align: center;

        .shop-title-content {
          line-height: $shop-title-height;
          height: $shop-title-height;
          .item-name {
            color: #999;
            margin-right: 12px;
          }
          .item-value {
            i {
              cursor: pointer;
              background-color: white;
              border: 1px solid $theme_color;
              color: $theme_color;
              padding: 5px;
              border-radius: 50%;
              margin: 0 10px;
            }
            i:hover {
              background-color: $theme_color;
              color: white;
            }
          }
        }
        .shop-title-content:after {
          content: '|';
          color: #d6d6d6;
          font-weight: bold;
          font-size: 16px;
          float: right;
        }
        .shop-last-title:after {
          content: '';
        }
      }

      .shop-change {
        float: right;
        margin-right: 15px;
        color: $theme_color;
        font-size: 12px;
        cursor: pointer;
      }

    }
    .shop-account {
      border-bottom: 1px solid $main-border-color;
      padding: 20px;
      margin: 20px 0;
      .shop-filter {
        margin-bottom: 20px;
        span {
          font-size: 12px;
          cursor: pointer;
          color: $theme_color;
          border: 1px solid $theme_color;
          padding: 2px 5px;
          border-radius: 4px;
          margin: 0 5px;
        }
        span:hover {
          background-color: $theme_color;
          color: white;
        }
        span.active {
          background-color: $theme_color;
          color: white;
        }

      }
      li {
        float: left;
        width: 25%;
        text-align: center;
        p {
          padding: 5px 0;
        }
        .item-name {
          color: #999;
        }
        .item-value {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .item-more {
          cursor: pointer;
          color: #999;
          font-size: 12px;
        }
      }
    }
    .shop-chart {

      .chart {
        padding-left: 10px;
        float: left;
        width: 50%;
        span {
          margin-left: 5px;
          font-size: 12px;
          color: #999;
        }
        .echarts {
          padding: 0 10px;
        }
      }
    }

  }

</style>
