<template>
  <div class="goods">
    <p>
      <el-button type="primary" size="small" :disabled="shop.useOrg === 1" @click="handleCreate">新增分类</el-button>
      <span class="yk-tip">门店分类:
        <el-tooltip content="门店小程序展示单独的分类信息" placement="bottom">
          <i class="el-icon-question"></i>
        </el-tooltip>
        <el-switch v-model="shop.useOrg"
                   @change="$emit('commit', shop)"
                   :active-value="0"
                   :inactive-value="1"></el-switch></span>
      <span class="yk-tip" v-if="shop.useOrg === 1">使用【企业分类】，小程序显示企业饮品分类及饮品</span>
      <span class="yk-tip" v-else>使用【门店分类】，小程序显示店铺饮品分类及饮品</span>
    </p>
    <div class="goods-info">
      <div class="goods-preview" v-if="sorts.length > 0">
        <div class="goods-sort">
          <div class="goods-sort-item" :class="{active: sort.uuid === selectSortUuid}"
               @click="handleSort(idx, sort.uuid)"
               v-for="(sort, idx) in sorts"
               :key="sort.uuid">
            <span>{{sort.name}}</span>
            <svg class="mp" aria-hidden="true" v-if="sort.icon">
              <use :xlink:href="'#'+sort.icon"/>
            </svg>
          </div>
        </div>
        <div class="goods-container" ref="goods" @scroll="handleScroller">
          <div v-for="sort in sorts" :key="sort.uuid">
            <p class="goods-container-sort"><span>{{sort.name}}</span>
              <svg class="mp" aria-hidden="true" v-if="sort.icon">
                <use :xlink:href="'#'+sort.icon"/>
              </svg>
            </p>
            <div class="goods-container-item" v-for="goods in sort.goods" :key="goods.uuid">
<!--              <div class="goods-img" :style="{backgroundImage: 'url('+goods.thumb+')'}" />-->
              <img class="goods-img" :src="goods.thumb" />
              <div class="text">
                <p class="text-name">{{goods.name}}</p>
                <p class="text-description">{{goods.description}}</p>
                <p>
                  <span class="text-price">¥{{goods.linePrice / 100 | F1000}}</span>
                  <span class="text-buy">选规格</span>
                </p>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div class="goods-preview" v-else>
        <empty-tip>
          <p class="yk-tip">暂无分类，点击上方【新增分类】新增商品分类</p>
        </empty-tip>

      </div>

      <div class="goods-edit">
        <template v-if="idx > -1">
          <el-row type="flex" class="goods-edit-container">
            <el-col :span="8">
                  <span class="name">
                    <span class="name-label">分类名称：</span>
                    <span class="name-text">{{sorts[idx].name}}
                      <svg class="mp" aria-hidden="true" v-if="sorts[idx].icon !== ''">
                        <use :xlink:href="'#'+sorts[idx].icon"/>
                      </svg>
                    </span>
                  </span>
              <el-button type="text" @click="addGoodsShow = true"><i class="el-icon-circle-plus"></i>新增商品</el-button>

            </el-col>
            <el-col :span="16" style="text-align: right">

              <el-button type="text" @click="handleCreate(sorts[idx])"><i class="el-icon-edit"></i> 编辑分类</el-button>
              <el-button type="text" class="warning" @click="handleDelete"><i class="el-icon-delete-solid"></i>删除分类
              </el-button>
            </el-col>
          </el-row>
          <el-row class="sort-goods">
            <el-col class="remark">商品列表</el-col>
            <template v-if="sorts[idx]">
              <el-col :span="6" v-for="(goods, i) in sorts[idx].goods" :key="goods.uuid">
                <div class="sort-goods-item">
                  <el-image :src="goods.thumb" style="width: 100px; height: 100px;" :fit="'cover'" lazy></el-image>
                  <div class="text">
                    <p class="text-name">{{goods.name}}</p>
                    <p>
                      <span class="text-remark">售价:</span>
                      <span class="text-price">¥{{goods.linePrice / 100 | F1000}}</span>
                    </p>
                  </div>
                  <div class="edit">
                    <div class="edit-container">
                      <div class="edit-button">
                        <el-button size="mini" @click="handleGoodsRemove(goods, i)" type="danger" plain>移除</el-button>
                        <el-button size="mini" type="primary" @click="handleGoodsEdit(goods)" plain>编辑</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </template>

          </el-row>
        </template>
        <empty-tip v-else>
          <p class="yk-tip">暂无数据,请点击左侧商品分类进行编辑操作</p>
        </empty-tip>
      </div>
    </div>

    <el-dialog :visible.sync="editSortShow"
               width="560px"
               style="padding-right:24px"
               title="分类设置">
      <el-form label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="sort.name" placehoder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item label="分类排序">
          <el-input-number v-model="sort.sort" placeholder="越小越靠前"></el-input-number>
        </el-form-item>
        <el-form-item label="分类图标">
          <el-select v-model="sort.icon">
            <el-option v-for="item in icons"
                       :key="item.icon"
                       :label="item.name"
                       :value="item.icon">
              <p class="goods-container-sort"><span>{{item.name}}</span>
                <svg class="mp" aria-hidden="true" v-if="item.icon">
                  <use :xlink:href="'#'+item.icon"/>
                </svg>
              </p>
            </el-option>

          </el-select>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleCommit">确定</el-button>
        <el-button @click="editSortShow=false">取消</el-button>
      </template>
    </el-dialog>

    <el-drawer :visible.sync="addGoodsShow"
               class="goods-dialog"
               title="新增商品">
      <select-goods ref="goods" @cancel="addGoodsShow = false"
                    @commit="handleGoodsBind"
                    :shop-uuid="shop.uuid"></select-goods>
    </el-drawer>

    <el-dialog :visible.sync="editGoodsVisible"
               top="10vh"
               class="edit-goods"
               title="编辑商品">
      <el-row :gutter="20">
        <el-col :span="9">
          <img :src="goods.thumb"/>
        </el-col>
        <el-col :span="15">
          <el-form class="goods-form">
            <el-form-item label="商品名称">
              <el-input v-model="goods.name" disabled/>
            </el-form-item>
            <el-form-item label="商品描述">
              <el-input v-model="goods.description" type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}" disabled/>
            </el-form-item>
            <el-form-item label="商品销量">
              <span>{{goods.sales}}</span>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-form label-width="100px" class="edit-good-div">
        <el-form-item label="商品起售价：">
          <el-input v-model.number="goods.linePrice" type="number">
            <span slot="append">元</span>
          </el-input>
          <span class="yk-tip">（当前起售价仅对门店商品有效）</span>
        </el-form-item>
        <el-form-item label="商品库存：">
          <el-input v-model.number="goods.stocks">
            <span slot="append">杯</span>
          </el-input>
          <span class="yk-tip">（当前库存数量仅对门店商品有效）</span>
        </el-form-item>
        <el-form-item label="虚拟销售：">
          <el-input v-model.number="goods.virtualSales">
            <span slot="append">杯</span>
          </el-input>
          <span class="yk-tip">（当前库存数量仅对门店商品有效）</span>
        </el-form-item>
        <el-form-item label="商品排序：">
          <el-input v-model.number="goods.sort">
          </el-input>
          <span class="yk-tip">（越小越靠前）</span>
        </el-form-item>
        <el-form-item>
          <el-button @click="editGoodsVisible = false">取消</el-button>
          <el-button type="primary" @click="handleUpdateSortGoods">保存</el-button>
        </el-form-item>

      </el-form>

    </el-dialog>
  </div>

</template>

<script>
  import {checkObj, fillObj} from "@/utils/check";

  import SelectGoods from './components/select-goods'

  export default {
    name: '',
    components: {
      SelectGoods
    },
    mounted: function () {
      this.loadSort(this.shop.uuid )

    },
    props: {
      shop: {
        type: Object,
        default: () => {
        }
      }
    },
    data: function () {
      return {
        scroll: {},
        idx: -1,
        editSortShow: false,
        addGoodsShow: false,
        editGoodsVisible: false,
        sort: {name: '', icon: '', uuid: '', sort: 0},
        selectSortUuid: '',
        sorts: [],
        goods: {
          uuid: '',
          name: '',
          description: '',
          thumb: '',
          linePrice: 0,
          stocks: 0,
          sales: 0,
          virtualSales: 0,
          sort: 0,
        },
        icons: [
          {name: '无图标', icon: ''},
          {name: '木瓜', icon: 'xm-mugua'},
          {name: '草莓', icon: 'xm-caomei'},
          {name: '猕猴桃', icon: 'xm-mihoutao'},
          {name: '梨', icon: 'xm-li'},
          {name: '西瓜', icon: 'xm-xigua'},
          {name: '石榴', icon: 'xm-shiliu'},
          {name: '胡萝卜', icon: 'xm-huluobu'},
          {name: '芒果', icon: 'xm-mangguo'},
          {name: '橘子', icon: 'xm-juzi'},
          {name: '香蕉', icon: 'xm-xiangjiao'},
          {name: '樱桃', icon: 'xm-yingtao'},
        ],
      }
    },
    watch: {
      shop: {
        handler: function (v) {
          this.loadSort(v.uuid)
        },
        deep: true
      }
    },
    methods: {
      loadSort(v) {
        if (!v) {
          return
        }
        this.$api('mkt.shop.sorts', {uuid: v}).then((data) => {
          this.sorts = data || []
          this.sorts.sort((m, n) => {
            return m.sort - n.sort
          })
          this.loadGoods(v)
        })
      },
      loadGoods(uuid) {
        this.$api('mkt.shop.goods', {uuid: uuid}).then((data) => {
          let map = {}
          data.forEach((d) => {
            let v = map[d.shopSortUuid]
            v = v || []
            v.push(d)
            map[d.shopSortUuid] = v
          })

          this.sorts = this.sorts.map((item) => {
            let goods = (map[item.uuid] || [])
            goods.sort((m, n) => {
              return m.sort - n.sort
            })
            return {...item, goods: goods}
          })
        })
      },
      handleGoodsEdit(goods) {
        fillObj(this.goods, goods)
        this.goods.linePrice = goods.linePrice
        this.goods.stocks = goods.stocks
        this.goods.sales = goods.sales
        this.goods.virtualSales = goods.virtualSales
        this.goods.sort = goods.sort
        this.goods.linePrice /= 100
        this.editGoodsVisible = true
      },
      handleGoodsRemove(goods, idx) {
        let vue = this
        this.$confirm(`此操作将会移除店铺中【${goods.name}】, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          vue.$api('mkt.shop.sortGoodsDelete', {goodsUuid: goods.uuid, shopUuid: this.shop.uuid}).then(() => {
            vue.sorts[vue.idx].goods.splice(idx, 1)
            vue.$message.success('移除成功')
          })
        })
      },
      handleUpdateSortGoods() {
        let goods = this.goods
        this.$api('mkt.shop.sortGoodsUpdate', {},
          {
            ...goods,
            linePrice: goods.linePrice * 100,
            shopUuid: this.shop.uuid,
            uuid: this.sorts[this.idx].uuid,
            goodsUuid: goods.uuid
          }).then(() => {
          let idx = this.sorts[this.idx].goods.findIndex((item) => {
            return item.uuid === goods.uuid
          })
          this.sorts[this.idx].goods[idx].linePrice = goods.linePrice * 100
          this.sorts[this.idx].goods[idx].stocks = goods.stocks
          this.sorts[this.idx].goods[idx].sort = goods.sort
          this.sorts[this.idx].goods.sort((m, n) => {
            return m.sort - n.sort
          })
          this.editGoodsVisible = false
          this.$message.success('更新成功')
        })
      },
      handleGoodsBind(data) {
        let body = {
          uuid: this.sorts[this.idx].uuid,
          shopUuid: this.shop.uuid,
          stocks: data.stocks,
          virtualSales: data.virtualSales,
          goods: data.goods,
        }

        this.$api('mkt.shop.sortGoodBind', {}, body).then(() => {
          this.loadGoods(this.shop.uuid)
          this.addGoodsShow = false
          this.$refs.goods.handleClear()
          this.$message.success('绑定成功')
        })


      },
      handleCreate(sort = {}) {
        this.sort = {uuid: '', name: '', icon: '', sort: 0}
        fillObj(this.sort, sort)
        this.editSortShow = true
      },
      handleDelete() {
        let vue = this
        this.$confirm('删除分类将导致分类绑定商品的价格数据被清除，是否删除', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          vue.$api('mkt.shop.sortDelete', {
            uuid: vue.sorts[vue.idx].uuid,
            shopUuid: vue.shop.uuid
          }).then(() => {
            vue.sorts.splice(vue.idx, 1)
            vue.idx = -1
            vue.$message.success('删除成功')
          })
        })
      },
      handleCommit() {
        let check = checkObj(this.sort, [
          {value: "name", message: "请填分类名称"},
        ])
        if (!check.status) {
          this.$message.warning(check.message)
          return
        }

        this.sort.shopUuid = this.shop.uuid
        if (this.sort.uuid) {
          this.$api('mkt.shop.sortUpdate', {}, this.sort).then(() => {
            fillObj(this.sorts[this.idx], this.sort)
            this.sorts[this.idx].icon = this.sort.icon
            this.sorts.sort((m, n) => {
              return m.sort - n.sort
            })
            this.$message.success('更新成功')
            this.editSortShow = false
          })
        } else {
          this.$api('mkt.shop.sortCreate', {}, this.sort).then((uuid) => {
            this.sort.uuid = uuid
            let sort = {uuid: '', name: '', icon: '', sort: 0}
            fillObj(sort, this.sort)
            this.sorts.push(sort)
            this.sorts.sort((m, n) => {
              return m.sort - n.sort
            })
            this.idx = this.sorts.findIndex((s) => {
              return s.uuid === this.selectSortUuid
            })
            this.editSortShow = false
          })
        }
      },
      calcScrollerIdx(scrollTop) {
        let idx = -1
        let itemHeight = 118
        let itemSortHeight = 20
        let hiddenHeight = 0
        for (let i = 0; i < this.sorts.length; i++) {
          hiddenHeight += itemSortHeight + (this.sorts[i].goods.length * itemHeight)
          if (scrollTop <= hiddenHeight) {
            idx = i
            break
          }
        }
        return idx
      },
      handleScroller() {
        let idx = this.calcScrollerIdx(this.$refs.goods.scrollTop)
        if (idx < 0) {
          idx = 0
        }
        this.idx = idx
        this.selectSortUuid = this.sorts[idx].uuid
      },
      handleSort(idx, uuid) {
        let hiddenHeight = 0
        let itemHeight = 118
        let itemSortHeight = 20
        if (this.sorts.length > idx) {
          for (let i = 0; i < idx; i++) {
            hiddenHeight += itemSortHeight + (this.sorts[i].goods.length * itemHeight)
          }
        }

        hiddenHeight += 5
        if (this.$refs.goods) {
          this.$refs.goods.scrollTop = hiddenHeight
        }

        let vue = this
        setTimeout(function () {
          vue.selectSortUuid = uuid
          vue.idx = idx
        }, 30)

      },
    },
  }
</script>

<style lang="scss" scoped>
  .edit-goods {
    ::v-deep .el-dialog__body {
      padding-top: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .goods-form {
      .el-form-item {
        margin-bottom: 4px;
      }
    }
  }

  .goods-dialog {
    ::v-deep .el-drawer {
      min-width: 960px;
      padding: 0 $container-padding;
      padding-bottom: $middle-space;

      .el-drawer__header {
        padding-left: 0;
        margin-bottom: $middle-space;
        font-size: 18px;
        color: $color-black;
      }
      .el-drawer__body{
        height: 100%;
        overflow-y: scroll;
      }
    }
  }

  .edit-good-div {
    border-top: 1px solid $content-border-color;
    padding-top: 32px;

    ::v-deep .el-input {
      width: 240px;
    }
  }

  .goods {
    display: flex;
    flex-direction: column;

    .yk-tip {
      margin: 0 $small-space;
    }

    .el-switch {
      margin-left: $small-space;
    }

    &-info {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      height: 554px;
      overflow-y: scroll;
      margin-top: $middle-space;
    }

    svg {
      font-size: 18px;
    }

    &-preview {
      border: 1px solid $main-border-color;
      width: 375px;
      display: flex;
      height: 100%;

      .goods-sort {
        width: 75px;
        overflow-y: scroll;
        background: $remark-border-color;

        &-item {
          height: 60px;
          padding-top: $middle-space;
          padding-left: $small-space;
          color: $remark-text-color;
          border-left: 3px solid $remark-border-color;
          font-size: 13px;
          text-align: left;


        }

        &-item:hover {
          cursor: pointer;
          background: white;
          border-color: white;
        }

        .active {
          border-color: $theme-color;
          background: white;
          color: black;
          font-weight: 500;
        }
      }

      .goods-container {
        flex-grow: 1;
        overflow-y: scroll;
        width: 300px;
        padding: $middle-space;

        &-sort {
          color: black;
          font-weight: 500;
        }

        &-item {
          padding-top: $middle-space;

          .goods-img {
            margin: $small-space;
            margin-left: 0;
            margin-top: 0;
            width: 80px;
            height: 80px;
            background: no-repeat center;
            float: left;
            object-fit: cover;
          }

          .text {
            width: 168px;
            float: left;

            &-name {
              @include font-medium();
              font-weight: 600;
              color: black;
            }

            &-description {
              @include font-little-h();
              overflow: hidden;
              /*  超出的文本隐藏 */

              display: -webkit-box;
              /* 将对象作为弹性伸缩盒子模型显示。 */

              -webkit-line-clamp: 2;
              /* 这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。 */

              -webkit-box-orient: vertical;
              margin-bottom: $small-space;
            }

            &-price {
              @include font-medium();
              font-weight: 600;
              color: black;
            }

            &-buy {
              color: white;
              font-size: 13px;
              background: $theme-color;
              border-radius: 20px;
              padding: 4px $middle-space;
              float: right;
            }
          }
        }

        &-item:after {
          clear: both;
          display: block;
          content: " ";
        }
      }
    }

    &-edit {
      flex: 1;
      margin-left: $middle-space;
      height: 100%;
      border: 1px solid $main-border-color;
      display: flex;
      flex-direction: column;

      .el-row {
        align-items: center;
      }

      &-container {
        padding: $middle-space;
        height: auto;
        border-bottom: 1px solid $main-border-color;

      }

      .remark {
        color: $remark-text-color;
        @include font-little();
        margin-left: $middle-space;
        margin-top: $middle-space;
      }

      .sort-goods {
        flex: 1;
        overflow-y: scroll;

        &-item {
          margin: $small-space;
          padding: $small-space;
          text-align: center;
          cursor: pointer;
          position: relative;

          .text {
            &-name {
              @include font-medium();
              font-weight: 500;
            }

            &-price {
              color: $theme-color;
              @include font-medium-s();
              font-weight: 500;
              display: inline-block;
              margin-left: 4px;
            }

            &-remark {
              color: $remark-text-color;
              @include font-little();
            }
          }

          .edit {
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: $remark-bg-color;
            &-container {
              position: relative;
              width: 100%;
              height: 100%;
            }

            &-button {
              top: 52%;
              left: calc(50% - 77px);
              position: absolute;

              .el-button {
                margin: auto;
                //display: block;
                margin-left: $middle-space;
              }
            }
          }
        }

        &-item:hover {
          @include board();

          .edit {
            display: block;
          }
        }

        .el-image {
          margin-top: $small-space;
          width: 120px;
          text-align: center;
        }
      }

      .name {
        margin-right: $middle-space;

        &-label {
          display: inline-block;
          margin-right: 4px;
          color: $remark-text-color;
          @include font-little();
        }

        &-text {
          @include font-medium();
          color: black;
          font-weight: 500;
        }
      }

      .remark {
        color: $remark-text-color;
        padding-left: $middle-space;
        @include font-little();
      }


      &-not {
        text-align: center;
        margin-top: 56px;

        p {
          color: $remark-text-color;
        }
      }

    }

  }
</style>
