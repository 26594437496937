<template>
  <div class="bind">
    <div class="bind-container">
      <div>
        <span class="label">直接支付模式：</span>
        <span class="name-tip"><el-switch v-model.number="bind.directPay" :active-value="1"
                                          :inactive-value="0"></el-switch></span>
      </div>
      <device-list ref="deviceBind" @select-change="handleSelectionChange" :select="true" title="可选设备" :device-list="handleDeviceList"
                   :device-union="loadDevice"></device-list>

    </div>
    <div class="bind-footer">
      <el-button type="primary" size="small" @click="handleCommit">提交</el-button>
      <el-button size="small" @click="$emit('cancel')">取消</el-button>
      <span style="margin-left: 16px; font-size: 12px">
          <span style="display: inline-block;margin-right: 8px;">已选设备数:{{bind.devices.length}}</span>
          <el-popover trigger="hover" placement="top" width="800">
            <el-table :data="bind.devices" max-height="560px">
              <empty slot="empty"></empty>
              <el-table-column label="设备信息" width="160px">
                <template slot-scope="scope">
                  <p> {{ scope.row.uuid }}</p>
                  <p> {{ scope.row.name }}</p>
                </template>
              </el-table-column>
              <el-table-column label="地址信息" width="260px">
                <span slot-scope="scope">{{scope.row.province}}{{scope.row.city}}{{scope.row.address}}</span>
              </el-table-column>
              <el-table-column label="最近登录" width="120px">
                <template slot-scope="scope">
                  {{new Date(scope.row.lastLoginAt * 1000).Format('yyyy-MM-dd')}}
                </template>
              </el-table-column>
              <el-table-column label="品牌名称" prop="brandName" width="120px"></el-table-column>
              <el-table-column label="产品名称" prop="productName" width="120px"></el-table-column>
              <el-table-column label="注册时间" width="120px">
                <template slot-scope="scope">
                  {{new Date(scope.row.registerAt * 1000).Format('yyyy-MM-dd')}}
                </template>

              </el-table-column>
              <el-table-column label="操作" fixed="right">
                <el-button size="small" type="primary" slot-scope="scope" @click="$refs.deviceBind.clearSingle(scope.row)"> 移除</el-button>
              </el-table-column>
            </el-table>
            <el-button slot="reference" type="text" size="mini"><i class="el-icon-view"></i> 查看</el-button>
          </el-popover>
          <el-button type="text" size="mini" @click="$refs.deviceBind.clearAll()"><i class="el-icon-delete"></i> 清空</el-button>
        </span>
    </div>
  </div>
</template>

<script>
  import DeviceList from '@/components/DeviceList'

  export default {
    name: '',
    components: {
      DeviceList
    },
    mounted: function () {
    },
    props: {
      uuid: String
    },
    data: function () {
      return {
        unBindDevices: [],
        bind: {
          directPay: 1,
          devices:[],
          deviceUuid: []
        }
      }
    },
    methods: {
      handleCommit() {
        if (this.bind.devices.length === 0) {
          this.$message.warning('请选取需要绑定的设备')
          return
        }
        let uuid = []
        this.bind.devices.forEach(item => {
          uuid.push(item.uuid)
        })
        this.$api('mkt.shop.bindDevice', {}, {
          uuid:this.uuid,
          directPay:this.bind.directPay,
          deviceUuid:uuid,
        }).then(() => {
          this.$emit('commit')
          this.$message.success('绑定成功')
          this.bind.devices = []
          this.bind.directPay = 1
        })
      },
      handleDeviceList(val, fn) {
        fn(this.unBindDevices.slice(val.start, val.start+val.limit))
      },
      loadDevice(val, fn) {
        let uuid = this.uuid || this.$route.params.uuid
        this.$api('mkt.shop.unBindDevices', {uuid: uuid, name: val.name}).then(data => {
          this.unBindDevices = data || []
          fn({
            list: this.unBindDevices.slice(0, val.limit),
            total: this.unBindDevices.length + ''
          })
        })
      },
      handleClear() {
        this.$refs.singleTable.clearSelection()
      },
      handleSelectionChange(val) {
        this.bind.devices = val || []

      },
      handleRemove(row) {
        let refsElTable = this.$refs.singleTable; // 获取表格对象
        refsElTable.toggleRowSelection(row, false);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bind {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-container{
      flex: 1;
      overflow-y: scroll;

    }
    &-container::before {
      border: none;
      height: 0;
    }

    &-footer{
      margin: $middle-space 0;
    }
  }
</style>
